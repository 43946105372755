// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getType = createAsyncThunk('getTypeAllData/getType', async () => {
    try {
        let response = await axios.get('/EmployeeType', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getTypeOption = createAsyncThunk('getTypeAllData/getTypeOption', async () => {
    try {
        let response = await axios.get('/EmployeeType?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteType = createAsyncThunk('getTypeAllData/deleteType', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/EmployeeType/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'EmployeeType delete Successfully.');
        await dispatch(getType());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createType = createAsyncThunk('getTypeAllData/createType', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/EmployeeType', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'EmployeeType added Successfully.');
        await dispatch(getType());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateType = createAsyncThunk('getTypeAllData/updateType', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeType/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'EmployeeType update Successfully.');
        await dispatch(getType());
        return Promise.resolve(response);
    } catch {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateTypeStatus = createAsyncThunk('getTypeAllData/updateTypeStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeType/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getType());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const promotionSlice = createSlice({
    name: 'getPromotionAllData',
    initialState: {
        employeetype: [],
        typeOption: []
    },

    extraReducers: (builder) => {
        builder.addCase(getType.fulfilled, (state, action) => {
            state.employeetype = action?.payload;
            return;
        });
        builder.addCase(getTypeOption.fulfilled, (state, action) => {
            state.typeOption = action?.payload;
            return;
        });
    }
});

export default promotionSlice.reducer;
