// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

//** Location Cascade Api
export const getPendingRequest = createAsyncThunk('dashbordSlice/getPendingRequest', async () => {
    try {
        let response = await axios.get('/Dashboard/pending-request-count', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getLeaveBalance = createAsyncThunk('dashbordSlice/getLeaveBalance', async () => {
    try {
        let response = await axios.get('/Dashboard/leavebalance', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getBirthday = createAsyncThunk('dashbordSlice/getBirthday', async () => {
    try {
        let response = await axios.get('/Dashboard/birthday', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getNewJoinee = createAsyncThunk('dashbordSlice/getNewJoinee', async () => {
    try {
        let response = await axios.get('/Dashboard/new-joinee', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getPunchTime = createAsyncThunk('dashbordSlice/getPunchTime', async () => {
    try {
        let response = await axios.get('/Dashboard/last-attendance-entry', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getGenderPercentage = createAsyncThunk('dashbordSlice/getGenderPercentage', async () => {
    try {
        let response = await axios.get('/Dashboard/gender-percentage', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const dashboard = createSlice({
    name: 'dashbordSlice',
    initialState: {
        dashboard: [],
        leaveBalance: [],
        birthdayOfMonth: [],
        PunchTime: [],
        newJoinee :[],
        genderPercentage:[]
    },
    extraReducers: (builder) => {
        builder.addCase(getPendingRequest.fulfilled, (state, action) => {
            state.dashboard = action?.payload;
            return;
        });
        builder.addCase(getLeaveBalance.fulfilled, (state, action) => {
            state.leaveBalance = action?.payload;
            return;
        });
        builder.addCase(getBirthday.fulfilled, (state, action) => {
            state.birthdayOfMonth = action?.payload;
            return;
        });
        builder.addCase(getPunchTime.fulfilled, (state, action) => {
            state.PunchTime = action?.payload;
            return;
        });
        builder.addCase(getNewJoinee.fulfilled, (state, action) => {
            state.newJoinee = action?.payload;
            return;
        });
        builder.addCase(getGenderPercentage.fulfilled, (state, action) => {
            state.genderPercentage = action?.payload;
            return;
        });
    }
});
export default dashboard.reducer;
