// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getResignation = createAsyncThunk('resiganationSliceData/getResignation', async (data) => {
    try {
        let response = await axios.get('/Resignation/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployees = createAsyncThunk('resiganationSliceData/getEmployees', async () => {
    try {
        let response = await axios.get('/Employee/all-employee', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDepartmentOption = createAsyncThunk('getDepartmentAllData/getDepartmentOption', async () => {
    try {
        let response = await axios.get('/EmployeeDepartment?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteResignation = createAsyncThunk('getResignationAllData/deleteResignation', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Resignation/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department delete Successfully.');

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createResignation = createAsyncThunk('getResignationAllData/createResignation', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Resignation', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Resignation added Successfully.');
        await dispatch(getResignation(0));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateResignation = createAsyncThunk('getResignationAllData/updateResignation', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Resignation/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Resignation updated succesfully.');
        // await dispatch(getAsset());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getResignationRemarks = createAsyncThunk('getResignationAllData/getResignationRemarks', async (id) => {
    try {
        let response = await axios.get('/Resignation/get-remarks/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateResignationStatus = createAsyncThunk('getResignationAllData/updateResignationStatus', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Resignation/request-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Resignation update succesfully.');

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const resignationSlice = createSlice({
    name: 'getResignationAllData',
    initialState: {
        resignation: [],
        resignationRemarks: [],
        employees:[]
    },
    reducers: {
        removeActiveClosed: (state) => {
            state.resignation = [];
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getResignation.fulfilled, (state, action) => {
            state.resignation = action?.payload;
            return;
        });
        builder.addCase(getEmployees.fulfilled, (state, action) => {
            state.employees = action?.payload;
            return;
        });
        builder.addCase(getResignationRemarks.fulfilled, (state, action) => {
            state.resignationRemarks = action?.payload;
            return;
        });
    }
});
export const { removeActiveClosed } = resignationSlice.actions;

export default resignationSlice.reducer;
