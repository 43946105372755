// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getReview = createAsyncThunk('dashboardSliceData/getReview', async () => {
    try {
        let response = await axios.get('/dashboard/review', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getDashBoardInPunch = createAsyncThunk('dashboardSliceData/getDashBoardInPunch', async () => {
    try {
        let response = await axios.get('/dashboard/in-punch', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getDashBoardSalary = createAsyncThunk('dashboardSliceData/getDashBoardSalary', async () => {
    try {
        let response = await axios.get('/dashboard/salary-details', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDashBoardSalarySlip = createAsyncThunk('dashboardSliceData/getDashBoardSalarySlip', async () => {
    try {
        let response = await axios.get('/dashboard/salary-slip-download', {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const dashboardSlice = createSlice({
    name: 'dashboardSliceData',
    initialState: {
        review: [],
        dashboardSalary: [],
        dashBoardInPunch: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getReview.fulfilled, (state, action) => {
            state.review = action?.payload;
            return;
        });
        builder.addCase(getDashBoardSalary.fulfilled, (state, action) => {
            state.dashboardSalary = action?.payload;
            return;
        });
        builder.addCase(getDashBoardInPunch.fulfilled, (state, action) => {
            state.dashBoardInPunch = action?.payload;
            return;
        });
    }
});

export default dashboardSlice.reducer;
